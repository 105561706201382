@font-face {
    font-weight: normal;
    font-family: 'Edmondsans Regular';
    font-style: normal;
    src: local('Edmondsans Regular'), url("./fonts/EdmondsansRegular_web.woff") format('woff');
  }

  @font-face {
    font-weight: normal;
    font-family: 'Edmondsans Medium';
    font-style: normal;
    src: local('Edmondsans Medium'), url('./fonts/EdmondsansMedium_web.woff') format('woff');
  }
  
  @font-face {
    font-weight: normal;
    font-family: 'Edmondsans Bold';
    font-style: normal;
    src: local('Edmondsans Bold'), url('./fonts/EdmondsansBold_web.woff') format('woff');
  }
  
  /* im-fell-dw-pica-sc-regular - latin */
  @font-face {
    font-weight: 400;
    font-family: 'IM Fell DW Pica SC';
    font-style: normal;
    src: url('./fonts/im-fell-dw-pica-sc-v12-latin-regular.eot');
    src: local(''),
      url('./fonts/im-fell-dw-pica-sc-v12-latin-regular.eot?#iefix') format('embedded-opentype'),
      url('./fonts/im-fell-dw-pica-sc-v12-latin-regular.woff2') format('woff2'),
      url('./fonts/im-fell-dw-pica-sc-v12-latin-regular.woff') format('woff'),
      url('./fonts/im-fell-dw-pica-sc-v12-latin-regular.ttf') format('truetype'),
      url('./fonts/im-fell-dw-pica-sc-v12-latin-regular.svg#IMFellDWPicaSC') format('svg');
  }
  
  :root {
    --black: #101010;
    --blue: #0cbdc6;
    --green: #03ff9f;
    --pink: #ff298c;
    --darkhouse: #E64C6A; 
    --connectedloner: #00F5F5;
    --journeystillness: #46E929;
    --silentrumble: #FFC900;
    --surface-1: var(--black);
  }

  html {
    overflow: hidden;
    height: -webkit-fill-available;
    height: stretch;
  }
  
  body {
    min-height: 100vh !important;
    min-height: -webkit-fill-available !important;
    min-height: stretch !important;
  }
  
  blockquote {
    font-family: 'IM Fell DW Pica SC', serif;
    line-height: 2;
  }
  
  button {
    min-width: var(--size-13);
  }
  
  button[data-variant='primary'] {
    color: var(--surface-1);
  }

  .countdown {
    font-size: 750%;
    color: var(--pink);
  }
  
  input {
    text-align: center;
  }

  body, #root {
    display: flex;
    height: auto;
    width: 100%;
  }

  .screen {
    font-weight: 900;
    font-size: var(--font-size-4);
    font-family: 'Edmondsans Regular', sans-serif;
    background-color: var(--surface-1);
    overflow: hidden;
    height: auto;
    white-space: pre-wrap;
  }

  .question {
    --icon-size: var(--size-9);
    --border-size: 1px;
  
    gap: 0;
    height: 100%;
    overflow: hidden;
    font-size: var(--font-size-2);
    text-align: center;
  }
  
  .question > * {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
  }
  
  .question__text {
    padding-bottom: calc(var(--icon-size) * 0.5);
    padding-inline: var(--size-2);
  }
 
  .question__answer_text {
    color: var(--black);
  }
  
  .question__answer.question__answer {
    position: relative;
    color: var(--black);
    text-transform: none;
    background-color: var(--surface-1);
    border: var(--border-size) solid var(--brand);
    border-radius: 0;
  }
  
  .question__icon {
    position: absolute;
    top: calc(var(--icon-size) * -0.5 - var(--border-size));
    left: 50%;
    width: var(--icon-size);
    margin-left: calc(var(--icon-size) * -0.5);
    padding: var(--size-2);
    border-radius: 100%;
  }
  
  .question__icon::before,
  .question__icon::after {
    position: absolute;
    border-radius: 100%;
    content: '';
    inset: 0;
  }
  
  .question__icon::before {
    margin: calc(var(--border-size) * -1);
    background: linear-gradient(to bottom, var(--brand) 50%, transparent 50%);
  }
  
  .question__icon::after {
    background: linear-gradient(to bottom, var(--surface-1) 55%, transparent 55%);
  }
  
  .question__icon img {
    position: relative;
    z-index: 10;
  }